<template>
    <div>
        <div class="header-section">
            <div class="container conatiner-custom">
                <div class="row login h-100">
                    <div class="col-md-2">
                        <div class="logo-mra">
                            <a href="https://www.themassrest.org/"><img src="MRA.png"></a>
                        </div>
                    </div>
                    <div class="col-md-10">
                        <div class="row justify-content-center">
                            <div class="col-xl-8 col-lg-8 col-md-12 login-inputs mb-3">
                                <login-input url="/mra-signup"></login-input>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <section class="banner-section">
            <div class="container">
                <div class="banner-caption">
                    <h2>MRA Training Platform</h2>
                    <p>
                        Training solutions to become a certified restaurant employee with the Massachusetts Restaurant Association
                    </p>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import LoginInput from "@/views/Pages/LoginInput.vue";

export default {
    components: {
        LoginInput,
    },
    mounted: function () {
        document.querySelector('title').textContent = "MRA";
    }
}
</script>
<style>
.mra-login .login-inputs {
    padding-top: 20px;
}
</style>