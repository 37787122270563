<template>
    <div>
        <footer id="footer-main">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12">
                        <ul class="social-link">
                            <li><a href="https://www.facebook.com/marestaurants"><i aria-hidden="true" class="fa fa-facebook"></i></a></li>
                            <li><a href="https://twitter.com/MassRestaurants"><i aria-hidden="true" class="fa fa-twitter"></i></a></li>
                            <li><a href="https://www.youtube.com/channel/UCTFwvRBI1dnwX46a7DZZ9nA"><i aria-hidden="true" class="fa fa-youtube-play"></i></a></li>
                            <li><a href="https://www.instagram.com/ma_restaurants"><i aria-hidden="true" class="fa fa-instagram"></i></a></li>
                            <li><a href="mailto:kmiller@massrestaurantassoc.org"><i aria-hidden="true" class="fa fa-envelope"></i></a></li>
                        </ul>
                    </div>
                    <div class="col-xl-9 col-lg-9 col-md-8 col-sm-12 col-12">
                        <ul class="footer-link">
                            <li>Massachusetts Restaurant Association - 160 East Main St. - Suite 2, Westborough, MA, 01581 Phone: (508) 303-9905 </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>
<script>
export default {}
</script>
<style>
ul.social-link, ul.footer-link {
    display: flex;
    list-style: none;
    padding: 0;
}

ul.social-link li {
    margin: 0px 5px;
}

ul.footer-link li {
    margin: 0px 5px;
}
.mra-login .footer-link li {
    color: #d7d7d7;
    font-size: 14px;
}

</style>
